<template>
  <div class="main">
    <div class="blind_edit">
      <div class="blind_edit_content">
        <div class="content flex">
          <div class="form">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-form-inline">
              <el-form-item label="当前期次">
                <span class="color66 paddL10 lineH40">{{ ruleForm.batchtime }}期</span>
              </el-form-item>
              <el-form-item label="省/国家级" prop="trainLevel">
                <el-radio-group v-model="ruleForm.trainLevel" class="marL10 lineH40 transformY5">
                  <el-radio label="99" v-if="train.doubleLevel==='1'">全部</el-radio>
                  <el-radio label="1" :disabled="train.doubleLevel==='0'&&train.trainLevel==='0'">省级</el-radio>
                  <el-radio label="0" :disabled="train.doubleLevel==='0'&&train.trainLevel==='1'">国家级</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="名字：" prop="name">
                <el-input v-model="ruleForm.name" class="marL10 w430" disabled></el-input>
              </el-form-item>
              <el-form-item label="身份证号：" prop="idcard">
                <el-input v-model="ruleForm.idcard" class="marL10 w430" disabled></el-input>
              </el-form-item>
              <el-form-item label="性别：" prop="sex">
                <el-radio-group v-model="ruleForm.sex" class="marL10 lineH40 transformY5">
                  <el-radio label="6">男</el-radio>
                  <el-radio label="7">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="视力情况：" prop="blindlevel">
                <el-radio-group v-model="ruleForm.blindlevel" class="marL10 lineH40 transformY5">
                  <el-radio label="1">全盲</el-radio>
                  <el-radio label="2">低视</el-radio>
                  <el-radio label="3">明眼</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="出生日期：" prop="birthday">
                <el-date-picker
                    v-model="ruleForm.birthday"
                    type="date"
                    class="marL10"
                    popper-class="eagle-a11y-uncut"
                    placeholder="选择日期">
                </el-date-picker>
                <!--class="marL10 lineH40"-->
              </el-form-item>
              <el-form-item label="专业：" prop="major">
                <el-input v-model="ruleForm.major" class="marL10 w430"></el-input>
              </el-form-item>
              <el-form-item label="职称：" prop="title">
                <el-radio-group v-model="ruleForm.title" class="marL10 lineH40 transformY5">
                  <el-radio label="1">初级</el-radio>
                  <el-radio label="2">中级</el-radio>
                  <el-radio label="3">副高</el-radio>
                  <el-radio label="4">正高</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="学历：" prop="edu">
                <el-radio-group v-model="ruleForm.edu" class="marL10 lineH40 transformY5">
                  <el-radio label="1">中专</el-radio>
                  <el-radio label="2">大专</el-radio>
                  <el-radio label="3">本科</el-radio>
                  <el-radio label="4">硕士</el-radio>
                  <el-radio label="5">博士</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="盲按证件：" prop="certificateNo">
                <el-input v-model="ruleForm.certificateNo" placeholder="请输入您的盲按证件" class="marL10 w430"></el-input>
              </el-form-item>
              <el-form-item label="领证日期：" prop="receiveTime">
                <el-date-picker
                    v-model="ruleForm.receiveTime"
                    type="date"
                    class="marL10"
                    popper-class="eagle-a11y-uncut"
                    placeholder="选择领证日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="工作单位：" prop="company">
                <el-input v-model="ruleForm.company" placeholder="请输入您的工作单位" class="marL10 w430"></el-input>
              </el-form-item>
              <el-form-item label="邮编：" prop="postalCode">
                <el-input v-model="ruleForm.postalCode" placeholder="请输入您的邮编号" type="number"
                          class="marL10 w430"></el-input>
              </el-form-item>
              <el-form-item label="通讯地址：" prop="address">
                <el-input v-model="ruleForm.address" placeholder="请输入您的详细地址" class="marL10 w430"></el-input>
              </el-form-item>
              <el-form-item label="邮箱：" prop="email">
                <el-input v-model="ruleForm.email" placeholder="请输入您的邮箱" class="marL10 w430"></el-input>
              </el-form-item>
              <el-form-item label="座机号：" prop="phone">
                <el-input v-model="ruleForm.phone" placeholder="请输入您的座机号" class="marL10 w430"></el-input>
              </el-form-item>
              <el-form-item label="手机号：" prop="mobile">
                <el-input v-model="ruleForm.mobile" placeholder="请输入您的手机号" type="number" class="marL10 w430"></el-input>
              </el-form-item>
              <el-form-item class="text_C marT20 paddT20">
                <el-button type="primary" class="w350" @click="submitForm('ruleForm')">提交</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="avatar">
            <div class="avatar_content">
              <div class="bs_text_oo select_name">请选择文件</div>
              <div class="avatars">
                <el-upload
                    class="avatar-uploader marL10"
                    action="/api/app-jycy-disabled/uploadImg"
                    :headers="uploadHeaders"
                    :show-file-list="false"
                    accept=".jpg,.png"
                    :on-success="handleAvatarSuccess1"
                    :on-error="erroupload">
                  <img v-if="ruleForm.photourl" :src="ruleForm.photourl" class="img_avatar block_inline">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <p class="fonT12 color99 marR20 paddR20">两寸彩色免冠相片</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
        <div class="content text_C">
          <p><img src="~/static/center/right_icon.png" alt="" class="inline-block middle marR5">提交成功</p>
          <p>您可以在
            <nuxt-link to="/center/" class="bs_text_oo" target="_blank">用户中心</nuxt-link>
            中查看
          </p>
        </div>
        <div class="footer text_C marT20 paddT20">
          <el-button type="primary" class="w100" @click="handleClose">知道了</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import qs from 'qs';

export default {
  name: "blindEdit",

  data() {
    return {
      status: 0,
      ruleForm: {
        id: '',
        batchtime: '',
        trainLevel: '',
        addType: '',
        name: '',
        photourl: '',
        idcard: '',
        sex: '',
        blindlevel: '',
        birthday: '',
        major: '',
        title: '',
        edu: '',
        certificateNo: '',
        receiveTime: '',
        company: '',
        postalCode: '',
        address: '',
        phone: '',
        mobile: '',
        applyTable: '',
      },
      rules: {
        trainLevel: [
          {required: true, message: '请选择考试方向', trigger: 'change'},
        ],
        name: [
          {required: true, message: '请正确填写您的名字', trigger: 'blur'}
        ],
        idcard: [
          {required: true, message: '请正确填写身份证号', trigger: 'blur'}
        ],
        sex: [
          {required: true, message: '请选择您的性别', trigger: 'change'}
        ],
        blindlevel: [
          {required: true, message: '请选择您的视力情况', trigger: 'change'}
        ],
        birthday: [
          {type: 'date', required: true, message: '请选择时间', trigger: 'change'}
        ],
        major: [
          {required: true, message: '请正确填写您的专业', trigger: 'blur'}
        ],
        title: [
          {required: true, message: '请选择您的职称', trigger: 'change'}
        ],
        edu: [
          {required: true, message: '请选择您的学历', trigger: 'change'}
        ],
        certificateNo: [
          {required: true, message: '请正确填写盲按证号', trigger: 'blur'}
        ],
        receiveTime: [
          {type: 'date', required: true, message: '请选择领证时间', trigger: 'change'}
        ],
        company: [
          {required: true, message: '请正确填写工作单位', trigger: 'blur'}
        ],
        postalCode: [
          {required: true, message: '请输入邮编号', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '请输入详细地址', trigger: 'blur'}
        ],
        mobile: [
          {required: true, message: '请正确输入手机号码', trigger: 'blur'}
        ],
        email: [
          {required: true, type: 'email', message: '请正确输入邮箱', trigger: 'blur'}
        ],
        applyTable: [
          {required: true, message: '请上传加盖公章后的报名表', trigger: 'blur'}
        ],
        photourl: [
          {required: true, message: '请上传头像图片', trigger: 'blur'}
        ],
      },
      url: {
        queryById: '/blindTrain/queryById',
        add: '/blindTrainApply/add',
      },
      train: [],
      city: [],
      logo: '',
      dialogVisible: false,
    }
  },
  methods: {
    handleChange() {

    },
    submitForm: function (formName) {
      let that = this;
      if (!that.ruleForm.photourl) {
        that.$message({
          type: 'warning',
          center: true,
          message: '请上传头像!'
        });
      } else {
        that.$refs[formName].validate((valid) => {
          if (valid) {
            that.ruleForm.addType = that.ruleForm.trainLevel;
            that.ruleForm.trainId = that.$route.query.id;
            that.$api.lanzhouMangrenAddApi(that.ruleForm).then(result => {
              if (result.data.success) {
                that.$alert('提交成功，可在用户中心查看进度。', '提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    that.$router.push('/lanzhou/center/blind/');
                  }
                });
                that.ruleForm = {}
              } else {
                that.$message({
                  type: 'fail',
                  center: true,
                  message: '信息提交失败!'
                });
              }
            })
          } else {
            that.$message({
              type: 'fail',
              center: true,
              message: '请完整提交表单项信息!'
            });
            return false;
          }
        });
      }
    },
    queryById: function (id) {
      let that = this;
      this.$api.lanzhouQueryByIdApi({id: id}).then(result => {
        if (result.data.success) {
          let data = result.data.data;
          that.train = data.train;
          that.ruleForm.trainLevel = data.train.trainLevel;
          that.ruleForm.batchtime = data.train.batchtime;
          this.$api.getMyInfo().then(res => {
            if (res.data.success) {
              let data = res.data.data;
              that.ruleForm.name = data.userName;
              that.ruleForm.idcard = data.idNumber;
            }
          })
        } else {
          that.$message.error("查询培训项目信息失败！");
        }
      })
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // handleAvatarSuccess(res, file) {
    //   this.ruleForm.applyTable = res.data;
    // },
    handleAvatarSuccess1(res, file) {
      this.ruleForm.photourl = res.msg;
      console.log(this.ruleForm.photourl)

    },
    erroupload: function () {
      this.$message({
        message: '图片上传失败，请重新上传！！',
        center: true
      })
    },
  },
  mounted() {

  },
  created() {
    if (this.$route.query.id) {
      this.queryById(this.$route.query.id);
    }
  }
}
</script>

<style scoped lang="less">

@import "~assets/lanzhou/blind.less";

button:disabled {
  color: #666666 !important;
}

.main {
  background: #f9f9f9;
}

.blind_edit {
  width: 1200px;
  margin: 0 auto 50px auto;
}

.blind_edit {
  padding-top: 20px;
  .pass {
    position: absolute;
    top: 75px;
    right: 160px;
  }

  .blind_edit_content {
    position: relative;
    background: white;

    .tip {
      height: 30px;
      line-height: 30px;
      padding: 0 13px;
      background: #FFF6EC;

      .review {
        font-size: 12px;
        color: #FC6C38;
        padding-right: 20px;
      }

      p.err {
        line-height: 30px;
        font-size: 12px;
        color: #E70E0E;

        a {
          color: #00924B;
          text-decoration: underline;
        }
      }
    }

    .content {
      padding: 30px;

      .form {
        width: 600px;

        .el-form {
          .el-form-item {
            .el-form-item__label {

            }
          }

        }
      }

      .avatar {
        position: absolute;
        top: 105px;
        right: 0;
        .avatar_content{
          display: flex;
          align-items: center;
          p{
            margin-top: 10px;
          }
         /deep/ .avatar-uploader {
            position: relative;
            width: 88px;
            height: 106px;
            //border: 1px solid #ddd;
            background: #f9f9f9;
            border-radius: 6px;
            cursor: pointer;
            .el-upload.el-upload--text {
              display: block !important;
              width: 100%;
              height: 100%;
            }

            img {
              width: 100%;
              height: 100%;
            }

            .el-icon-plus {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 24px;
              color: #00924B;
            }
          }

        }
      }
    }
  }
}
</style>
